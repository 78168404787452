<template>
  <div class="p-index">
    <headers></headers>
    <div  class="bg"></div>
    <div class="content">
      <el-breadcrumb  style="padding: 15px 0" separator="/">
        <el-breadcrumb-item>个人首页</el-breadcrumb-item>
        <el-breadcrumb-item><a>企业引导</a></el-breadcrumb-item>
      </el-breadcrumb>
      <div class="flex">
        <div class="btn btn1">
          <el-button type="primary" style="margin-top: 200px" round @click="gonew">申请新企业</el-button>
        </div>
        <div class="btn btn1">
          <el-button type="primary" style="margin-top: 200px"  round @click="goadd">加入已有企业</el-button>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import headers from '../../components/headers'
import bottom from "../../components/bottom";

export default {
  name: "qyyd",
  methods:{
    gonew(){
      this.$router.push({
        path:'/zhuce'
      })
    },
    goadd(){
      this.$router.push({
        path:'/jryd'
      })
    }
  },
  components: {
    headers, bottom
  },
}
</script>

<style lang="scss" scoped>
.p-index{
  background: #f5f5f5;
}
  .bg{
    width: 100%;
    height: 200px;
    background: url("../../assets/icons/personindex.png") no-repeat;
    background-size: cover;
  }
  .content{
    width: 1200px;
    margin: 0 auto;
    min-height: 1000px;
    .btn{
      text-align: center;
      width: 321px;
      height: 321px;
      margin: 102px;
      background: #fff;
      border-radius: 36px;
    }
  }
</style>